@import "src/styles/animations";
@import "src/styles/fonts";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  min-width: 1024px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #F8F8F8!important;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

label {
  margin: 0;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  display: block;
}

button {
  font-family: inherit !important;
  outline: none !important;

  &:active {
    outline: none;
  }
}

.tox-notifications-container {
  opacity: 0;
}

.color {
  color: red!important;
}